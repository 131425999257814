import React from "react"
import { Router } from "@reach/router"

const App = () => (
  <div>
    <Router>

    </Router>
  </div>
)

export default App